import React, { useEffect, useState } from "react";
import { IoMdArrowRoundForward } from "react-icons/io";
import { AnimatePresence, motion } from "framer-motion";
import style from "./guest-hints.module.scss";
import { guessParser } from "../../../../helpers/helpers";
import PropTypes from "prop-types";

const GuestHints = ({
    guess,
    answer,
    open,
    property,
    guessTriggered,
    setOpenGuessHint,
    setHasOpenedGuessHint,
}) => {
    const [countdown, setCountdown] = useState(10);

    // Parsed guess
    const parsedGuess = guessParser(guess);

    // Get raw %
    const rawPctg = parsedGuess / answer;

    /**
     * Handles calculation of arrow rotation
     * @param {number} rawPctg raw percentage
     * @returns {number} angle of rotation
     */
    const getRotation = (rawPctg) => {
        // Calc %
        const pctg = rawPctg * 100;

        // If % is equal to 100, return 0 and hide hint
        if (pctg === 100) return 0;

        // If % is less than 100, indicate to go higher
        if (pctg < 100) return pctg <= 50 ? -90 : -45;

        // If % is more than 100, indicate to go lower at 45deg
        // If % is more than or equal to 200, indicate to go lower at 90deg
        return pctg >= 200 ? 90 : 45;
    };

    useEffect(() => setCountdown(10), [property]);

    useEffect(() => {
        if (countdown !== -1 && guessTriggered) {
            if (countdown === 0) {
                setOpenGuessHint(false);
                setHasOpenedGuessHint(true);
            }

            const interval = setInterval(() => {
                setCountdown((prev) => prev - 1);
            }, 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [countdown, guessTriggered]);

    return (
        <AnimatePresence mode="wait">
            {open && (
                <motion.div
                    key={"HINT"}
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 19, opacity: 1 * (countdown / 10) }}
                    exit={{ height: 0, opacity: 0 }}
                    className={style.guest_hint_container}
                >
                    <h6>Guess {parsedGuess > answer ? "Lower" : "Higher"}</h6>
                    <motion.span
                        initial={{ rotate: 0 }}
                        animate={{ rotate: getRotation(rawPctg) }}
                        exit={{ rotate: 0 }}
                    >
                        <IoMdArrowRoundForward fontSize={12} />
                    </motion.span>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

GuestHints.propTypes = {
    guess: PropTypes.string.isRequired,
    answer: PropTypes.number.isRequired,
    open: PropTypes.bool.isRequired,
    guessTriggered: PropTypes.bool.isRequired,
    setOpenGuessHint: PropTypes.func.isRequired,
    setHasOpenedGuessHint: PropTypes.func.isRequired,
    property: PropTypes.object.isRequired,
};

export default GuestHints;
