import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Tooltip } from "react-tooltip";
import { IoCloseCircleOutline } from "react-icons/io5";
import { motion } from "framer-motion";
import "./custom-tooltip.scss";

/**
 * CustomTooltip component displays a tooltip with optional custom content or a string.
 *
 * @param {Object} props - The props object containing configuration for the tooltip.
 * @param {string} props.id - The id of the tooltip. It must match the data-tooltip-id of the element where the tooltip is displayed.
 * @param {string} [props.placement='top'] - The placement of the tooltip (top, bottom, left, right).
 * @param {React.ReactNode} [props.customContent=null] - Custom content to be displayed inside the tooltip.
 * @param {string} [props.contentString=''] - Fallback string content to be displayed if customContent is not provided.
 * @param {boolean} [props.open=false] - Controls the visibility of the tooltip.
 * @param {function} [props.onClose=()=>{}] - Function to call when the close button is clicked.
 * @param {boolean} [props.animate=false] - Controls whether the tooltip should animate.
 * @param {number|null} [props.autoCloseTime=null] - The time in milliseconds after which the tooltip should automatically close. If null, the tooltip won't auto-close.
 */
function CustomTooltip({
    id,
    placement,
    customContent,
    contentString,
    open,
    onClose,
    animate,
    autoCloseTime,
    openDelay,
}) {
    const [show, setShow] = useState(false);

    const bounceTransition = {
        type: "spring",
        stiffness: 400,
        damping: 10,
    };

    const bounceVariants = {
        hidden: { scale: 0.8, opacity: 0 },
        visible: { scale: 1, opacity: 1 },
        exit: { scale: 0.8, opacity: 0 },
    };

    useEffect(() => {
        let timer;
        if (open && autoCloseTime) {
            timer = setTimeout(onClose, autoCloseTime);
        }
        return () => clearTimeout(timer);
    }, [open, autoCloseTime, onClose]);

    useEffect(() => {
        if (open) {
            setTimeout(() => setShow(true), openDelay);
        } else {
            setShow(false);
        }
    }, [open]);

    return (
        <Tooltip
            id={id}
            className="custom-tooltip"
            place={placement}
            clickable
            render={() => (
                <div className="content">
                    <motion.div
                        initial="hidden"
                        animate={animate ? "visible" : ""}
                        exit="exit"
                        variants={bounceVariants}
                        transition={bounceTransition}
                    >
                        <button onClick={onClose} className="close-btn">
                            <IoCloseCircleOutline fontSize={17} />
                        </button>

                        <div className="content-texts">
                            {customContent ? customContent : <p className="mb-0">{contentString}</p>}
                        </div>
                    </motion.div>
                </div>
            )}
            isOpen={show}
        />
    );
}

CustomTooltip.propTypes = {
    id: PropTypes.string.isRequired,
    placement: PropTypes.string,
    customContent: PropTypes.node,
    contentString: PropTypes.string,
    open: PropTypes.bool,
    onClose: PropTypes.func,
    animate: PropTypes.bool,
    autoCloseTime: PropTypes.number,
    openDelay: PropTypes.number,
};

CustomTooltip.defaultProps = {
    placement: "top",
    customContent: null,
    contentString: "",
    open: false,
    onClose: () => {},
    animate: false,
    autoCloseTime: null,
    openDelay: 0,
};

export default CustomTooltip;
